




















































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import TransferDialog from "./TransferDialog.vue";
import CloseDialog from "./ClosingDialog.vue";
import { IAttendencyList } from "../../types/chat/chat-attendency";
@Component({
  components: {
    TransferDialog,
    CloseDialog,
  },
})
export default class ContactArea extends Request {
  @Prop() type: string;
  @Prop() conversation: IAttendencyList;
  @Prop() drawerDocs: boolean;
  @Prop() drawerData: boolean;
  observations: any = [];
  allObservations: any = [];
  lastObservationsByContact: any = {};
  dialog: boolean = false;
  newObservation: string = "";
  textAreaObservation: boolean = false;

  async mounted() {
    await this.loadData();
  }

  async loadData() {
    const observation = await this.get(this.paths.personObservationPath);
    this.allObservations = observation[0];
  }

  getLastObservation(personId) {
    return this.allObservations
      .filter((observation) => observation.personId === personId)
      .sort((a, b) => b.id - a.id)
      .slice(0, 1);
  }
  updateDrawerDocs() {
    this.drawerDocs === true
      ? this.$emit("drawerDocs", false)
      : this.$emit("drawerDocs", true);
  }
  updateDrawerData() {
    this.drawerData === true
      ? this.$emit("drawerData", false)
      : this.$emit("drawerData", true);
  }
  addComponent(): void {
    this.textAreaObservation = true;
  }
  async onSave() {
    this.observations.push({
      personId: this.conversation.contact.id,
      userAgentId: this.conversation.userAgentId,
      attendencyId: this.conversation.attendencyId,
      observation: this.newObservation,
    });
    this.observations.map(async (observation) => {
      const result = await this.save(
        this.paths.personObservationPath,
        observation
      );
      this.loadData();
      this.observations = [];
      return result;
    });
    this.textAreaObservation = false;
    this.newObservation = "";
  }
}
