


























































































































































































































































































































































import { Component } from "vue-property-decorator";
import Request from "../../services/request";
import { io } from "socket.io-client";
import env from "../../services/env";
import { paths } from "../../services/apiPaths";
import ContactArea from "./ContactArea.vue";
import Active from "../../components/ActiveDialog.vue";
import ChatBody from "./ChatBody.vue";
import MidiaAndDocs from "./MidiaAndDocs.vue";
import { mapGetters } from "vuex";
// import ExternalChat from "./ExternalChat.vue";
// import { translate } from "../../plugins/i18n/index";
import History from "./History.vue";
import DataArea from "./DataArea.vue";
import SearchText from "./SearchText.vue";
import PendingAttendencyList from "./PendingAttendencyList.vue";
import { EventBus } from "../../services/eventBus";
import { IUserAgent } from "../../types/chat/Chat-userAgent";
import { IUser } from "../../types/User";
import { IAttendencyList } from "../../types/chat/chat-attendency";

@Component({
  components: {
    Active,
    ChatBody,
    ContactArea,
    DataArea,
    MidiaAndDocs,
    PendingAttendencyList,
    SearchText,
    History,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class Chat extends Request {
  drawer: boolean = true;
  overlay: boolean = false;
  selected: boolean = null;
  drawerSearch: boolean = false;
  drawerHistory: boolean = false;
  tab: number = null;
  dialog: boolean = false;
  selectedImage: string = "";
  conversation: IAttendencyList = {};
  drawerDocs: boolean = false;
  drawerData: boolean = false;
  rerender: number = 1;
  rerenderNotification: number = 1;
  socketWhatsappUrl: string = `${env.protocol}${env.socketWhatsapp}`;
  socketUrl: string = `${env.protocol}${env.socket}`;
  socket = io(this.socketUrl, {
    path: `${paths.socketPath}`,
    transports: ["websocket", "polling"],
    reconnection: true,
  });
  drawerContact: boolean = false;
  fromMe = [];
  userAgentList: IUserAgent[] = [];
  getUser!: () => IUser;
  user: IUser = {};
  currentattendency: IAttendencyList = {};
  socketWhatsapp = io(this.socketWhatsappUrl, {
    path: `${paths.socketWhatsappPath}`,
    transports: ["websocket", "polling"],
    reconnection: true,
  });
  userAgent: IUserAgent = {};
  itemsPerPage: number = 10;
  iconSent = {};
  agentStatus: string = null;
  entriesListWaiting: IAttendencyList[] = [];
  entriesListAttendency: IAttendencyList[] = [];
  itemsPerPageMessagesWhatsapp: number = 98;
  pendingList: IAttendencyList[] = [];

  openGetDialog(attendency) {
    this.dialog = true;
    this.currentattendency = attendency;
  }
  async mounted() {
    this.loadData();
    this.checkStatus();
  }
  async waitingAttendency() {
    const result = await this.get(
      this.paths.listAttendencyPath + `?skip=${1}&take=${this.itemsPerPage}`
    );
    this.entriesListWaiting = result[0];
    this.entriesListWaiting.map((item) => {
      item.messages = [];
      item.randomNumber = 1;
    });
  }
  async checkStatus() {
    const validStatus = await this.get(
      this.paths.queueUserAgentStatus + this.user.userAgent?.id
    );
    const currenctStatus = validStatus[0];
    if (currenctStatus && currenctStatus[0] && currenctStatus[0].status) {
      this.agentStatus = currenctStatus[0].status;
    }

    if (this.agentStatus === "F") {
      this.drawer = false;
    }
  }
  async loadData() {
    this.conversation = {};
    this.socketWhatsappUrl = `${env.protocol}${env.socketWhatsapp}`;
    this.socketUrl = `${env.protocol}${env.socket}`;
    this.user = this.getUser();

    const userAgentListResult = await this.get(this.paths.userAgentPath);
    this.userAgentList = userAgentListResult[0];
    this.userAgent = this.userAgentList.find((i) => i.userId === this.user.id);

    const result = await this.get(
      this.paths.attendencyQueueUserAgent +
        `?userAgentId=${this.userAgent.id}&skip=${1}&take=${this.itemsPerPage}`
    );
    this.entriesListAttendency = result[0];
    this.entriesListAttendency.map((item) => {
      item.messages = [];
      item.randomNumber = 1;
      item.lastMessage = item.attendency.lastMessage.replace(/"/g, "");
    });
    this.pendingAttendency();
  }
  async getRoom(attendency) {
    const userAgent = this.userAgentList.find((i) => i.userId === this.user.id);
    const entity = {
      userAgentId: userAgent.id,
      // queueId: 1,
      attendencyStatus: 2,
      attendencyId: attendency.attendencyId,
      editorUserId: this.user.id,
    };

    await this.put(this.paths.closureAttendencyPath + attendency.id, entity);

    this.loadData();
    this.rerender++;
    this.tab = 0;
    this.dialog = false;
  }
  created() {
    this.listeningSocket();
    EventBus.$on("update-chat", (status) => {
      this.agentStatus = status;
      status === "F" ? (this.drawer = false) : (this.drawer = true);
    });
  }
  updateMessage(message) {
    if (message.userName === this.user.firstName) {
      this.fromMe[message.userName] = true;
    } else {
      this.fromMe[message.userName] = false;
    }
    this.conversation.messages.push(message);

    this.rerender += 1;
  }
  async pendingAttendency() {
    const pending = await this.get(
      this.paths.pendingAttendencyPath +
        `?skip=1&take=10&userAgentId=${this.userAgent.id}`
    );
    this.pendingList = pending[0];
    this.pendingList.map((item) => {
      item.messages = [];
      item.randomNumber = 1;
      item.lastMessage = item.attendency.lastMessage.replace(/"/g, "");
    });
  }
  async listeningSocket() {
    this.socket.on("connect", () => {
      this.socket.on("message", (message) => {
        const { userName } = message;
        const { firstName } = this.user;
        this.fromMe[userName] = userName === firstName ? true : false;

        this.entriesListAttendency.map((item) => {
          if (
            `${item.contact.firstName}${item.contact.email}` === message.room
          ) {
            item.notification = item.attendency.unreadMessages;
            message.typeMessage = "text";
            if (item.attendency && item.attendency.lastMessage) {
              item.attendency.lastMessage = message.text;
            }

            item.messages.push(message);
            userName === firstName ? false : this.playSound();

            this.rerender++;
          }
        });
      });
    });

    const messageIcons = {
      sent: {
        icon: "mdi-check",
        color: "#515354",
      },
      delivered: {
        icon: "mdi-check-all",
        color: "#515354",
      },
      read: {
        icon: "mdi-check-all",
        color: "#00b2ff",
      },
    };

    this.socketWhatsapp.on("connect", () => {
      this.socketWhatsapp.on("whatsapp", async (message) => {
        const {
          userName,
          typeMessage,
          messageId,
          unreadMessages,
          protocolNumber,
        } = message;
        const { messages } = this.conversation;
        const { firstName } = this.user;
        this.fromMe[userName] = userName === firstName ? true : false;

        switch (typeMessage) {
          case "text":
          case "image":
          case "video":
          case "audio":
          case "location":
          case "contacts":
            for (const item of this.conversations) {
              const channel = await this.get(
                this.paths.channelPath + item.attendency.channelId
              );
              if (
                channel.active &&
                item.attendency.protocolNumber === protocolNumber
              ) {
                item.notification = unreadMessages;
                this.rerenderNotification++;

                if (item.attendency.lastMessage) {
                  item.lastMessage = message.message;

                  this.rerenderNotification++;
                }
                item.messages.push(message);
                this.rerender++;
                this.playSound();
              }
            }
            break;
          case "sent":
          case "delivered":
          case "read":
            messages.forEach((msg) => {
              if (msg.messageId === messageId) {
                msg.iconSent = messageIcons[typeMessage];
              }
            });
            break;
          default:
            break;
        }

        this.rerender++;
      });
      this.socketWhatsapp.on("transfer", (data) => {
        console.log(data);
      });
    });
  }
  backToAttendency(value) {
    this.tab = value;
    this.loadData();
  }
  rooms(room) {
    this.entriesListAttendency.push(room);
  }
  selectedImageValue(value) {
    this.selectedImage = value;
  }
  overlayValue(value) {
    this.overlay = value;
  }
  valueDrawerContact(value) {
    this.drawerContact = value;
  }
  valueDrawerDocs(value) {
    this.drawerDocs = value;
  }
  valueDrawerSearch(value) {
    this.drawerSearch = value;
  }
  valueDrawerHistory(value) {
    this.drawerHistory = value;
  }
  closeHistoricDialog(value) {
    this.drawerHistory = value;
  }
  valueDrawerData(value) {
    this.drawerData = value;
  }
  updateDrawer(value) {
    this.drawer = value;
  }
  async chargeWhatsapp(conversation) {
    this.socketWhatsapp.emit("room", {
      protocolNumber: conversation.attendency.protocolNumber,
    });

    if (conversation.randomNumber === 1) {
      conversation.messages = [];
      const messagesHistory = await this.get(
        `${this.paths.archiveWhatsappPath}?contactId=${
          conversation?.contact.id
        }&skip=${1}&take=${this.itemsPerPageMessagesWhatsapp}`
      );

      messagesHistory.forEach((message) => {
        console.log(message)
        const { userName, typeMessage, messageId, protocolNumber } = message;
        const { attendency, messages } = conversation;
        const { firstName } = this.user;
        const messageIcons = {
          sent: { icon: "mdi-check", color: "#515354" },
          delivered: { icon: "mdi-check-all", color: "#515354" },
          read: { icon: "mdi-check-all", color: "#00b2ff" },
        };

        this.fromMe[userName] = userName === firstName;

        switch (typeMessage) {
          case "text":
          case "location":
            if (
              attendency &&
              attendency.id &&
              attendency.protocolNumber === protocolNumber
            ) {
              message.message = message.message.replace(/"/g, "");
              messages.push(message);

              this.rerender++;
            }
            break;
          case "contacts":
            if (
              attendency &&
              attendency.id &&
              attendency.protocolNumber === protocolNumber
            ) {
              messages.push(message);

              this.rerender++;
            }
            break;
          case "image":
          case "video":
          case "document":
          case "audio":
            if (
              attendency &&
              attendency.id &&
              attendency.protocolNumber === protocolNumber
            ) {
              message.message = message.message
                .slice(1)
                .replace(/"/g, "")
                .replace(/=/g, "");
              messages.push(message);
              this.rerender++;
            }
            break;
          case "sent":
          case "delivered":
          case "read":
            messages.forEach((msg) => {
              if (msg.messageId === messageId) {
                msg.iconSent = messageIcons[typeMessage];
              }
            });
            break;
          default:
            if (message?.queueId) {
              messages.push(message);
            }
            break;
        }

        this.rerender++;
        conversation.randomNumber += 1;
      });
    }
  }
  async chargeWebChat(conversation) {
    await this.patch(
      this.paths.attendencyPath + this.conversation.attendency.id,
      {
        unreadMessages: 0,
      }
    );
    this.socket.emit("select_room", {
      userName: this.user.firstName,
      room: `${conversation.contact.firstName}${conversation.contact.email}`,
      email: conversation.contact.email,
      companyId: conversation.attendency.companyId,
      channelId: conversation.attendency.channelId,
      editorUserId: this.user.id,
    });

    this.socket.once("archive", (archive) => {
      archive.map((item) => {
        item.typeMessage = "text";
        if (item.userName === conversation.contact.firstName) {
          this.fromMe[item.userName] = false;
        } else {
          this.fromMe[item.userName] = true;
        }
      });

      this.conversation.messages = [...this.conversation.messages, ...archive];
      this.rerender++;
    });
    if (this.conversation.messages.length > 0) {
      this.socket.off("archive");
    }
  }
  async openMessages(conversation) {
    this.drawerHistory = false;
    this.drawerSearch = false;
    if (this.$vuetify.breakpoint.smAndDown) {
      document.getElementById("main").style.display = "block";
      document.getElementById("left").style.display = "none";
    }
    this.conversation = conversation;
    if (conversation.attendency.channelType === "C") {
      this.chargeWebChat(conversation);
    }
    if (conversation.attendency.channelType === "W") {
      console.log(conversation)
      await this.patch(
        this.paths.attendencyPath + this.conversation.attendency.id,
        {
          unreadMessages: 0,
        }
      );
      this.chargeWhatsapp(conversation);

      conversation.notification = 0;
    }
  }
  resolveChatIcon(iconType) {
    switch (iconType) {
      case "C":
        return "mdi-chat";
      case "W":
        return "mdi-whatsapp";
      case "T":
        return "mdi-telegram";
      default:
        throw new Error("Invalid icon type: " + iconType);
    }
  }
  playSound() {
    const notificationSound = require("../../assets/bel.wav");
    var audio = new Audio(notificationSound);
    audio.play();
  }
  clearCurrenctAttendency(value) {
    this.conversation = value;
  }
  messageToSearch(value) {
    document.getElementById(value._id).scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    const element = document.getElementById(value._id);
    element.classList.add("pulse");
    setTimeout(() => {
      element.classList.remove("pulse");
    }, 2000);
  }
  get iconType() {
    return this.iconSent;
  }
  get listWaiting() {
    return this.entriesListWaiting;
  }
  get conversations() {
    return this.entriesListAttendency;
  }
}
