













































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { format } from "date-fns";
import { mapGetters } from "vuex";
import { translate } from "../../plugins/i18n";
import { IUser } from "../../types/User";
import { IAttendencyList } from "../../types/chat/chat-attendency";

@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class ClosingDialog extends Request {
  @Prop() readonly user: IUser;
  @Prop() readonly conversation: IAttendencyList;
  @Prop() readonly type: boolean;
  @Prop() readonly loadData: () => void;
  getUser!: () => IUser;
  editorUserId: number = null;
  dialog: boolean = false;
  attendencyQueueList = [];
  itemsPerPage: number = 300;
  async mounted() {
    const user = this.getUser();
    this.editorUserId = user.id;
    const result = await this.get(
      this.paths.attendencyQueueUserAgent +
        `?userAgentId=${user.userAgent.id}&skip=${1}&take=${this.itemsPerPage}`
    );
    this.attendencyQueueList = result[0];
  }
  async onSave(conversation) {
    const attendencyQueueId = this.attendencyQueueList.find(
      (item) => item.attendencyId === conversation.attendencyId
    ).id;

    const today = new Date();
    const formattedDate = format(today, "yyyy-MM-dd HH:mm:ss");

    const result = await this.put(
      this.paths.closureAttendencyPath + attendencyQueueId,
      {
        attendencyStatus: 3,
        userAgentId: conversation.userAgentId,
        editorUserId: this.user.id,
        queueId: conversation.queueId,
        attendencyId: conversation.attendency.id,
        endDate: formattedDate,
      }
    );
    // if (result && result.isLastMessage === true) {
    const finalMessage = translate("finalMessageChat");
    this.$emit("end_message", finalMessage);
    this.loadData();
    this.dialog = false;
    return result;
    // } else {
    //   this.loadData();
    //   this.dialog = false;
    //   return result;
    // }
  }
}
