

























































































































































































































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import TransferDialog from "./TransferDialog.vue";
import CloseDialog from "./ClosingDialog.vue";
import { translate } from "../../plugins/i18n/index";
import MessageContainer from "./MessageContainer.vue";
import { paths } from "../../services/apiPaths";
import ContactDialog from "./ContactDialog.vue";
import PendingAttendency from "./PendingAttendency.vue";
import History from "./History.vue";
import { VEmojiPicker } from "v-emoji-picker";
import { IAttendencyList } from "../../types/chat/chat-attendency";
import { IUser } from "../../types/User";

@Component({
  components: {
    TransferDialog,
    CloseDialog,
    MessageContainer,
    ContactDialog,
    PendingAttendency,
    History,
    VEmojiPicker,
  },
})
export default class ActiveDialog extends Request {
  @Prop() conversation: IAttendencyList;
  @Prop() drawerContact: boolean;
  @Prop() fromMe: boolean;
  @Prop() drawer: boolean;
  @Prop() socket: any;
  @Prop() user: IUser;
  @Prop() rerender: number;
  @Prop() iconType: string;
  @Prop() loadData: () => void;
  @Prop() drawerSearch: boolean;
  @Prop() drawerHistory: boolean;
  @Prop() agentStatus: string;
  @Prop({ default: true }) isMainChat: boolean;
  i18n = {
    search: "Pesquisar...",
    categories: {
      Activity: "Atividades",
      Flags: "Bandeiras",
      Foods: "Comida",
      Frequently: "Frequentes",
      Objects: "Objetos",
      Nature: "Natureza",
      Peoples: "Pessoas",
      Symbols: "Símbolos",
      Places: "Locais",
    },
  };
  openEmojiPicker: boolean = false;
  imageToUpload: string = null;
  isRecording: boolean = false;
  message: string = "";
  profileUserAvatar = require("../../assets/avatar.png");
  mediaType: string = "";
  src: string = "";
  overlay: boolean = false;
  device = null;
  mediaRecorder: any = null;
  audioChunks: any = [];
  recordedVoice: string = null;
  position: GeolocationCoordinates = null;
  hasNamePermission = false;
  async mounted() {
    this.device = this.getMedia({ audio: true });
    navigator.geolocation.getCurrentPosition((position) => {
      this.position = position.coords;
    });
    const result = await this.get(this.paths.configurationPath);
    this.hasNamePermission = result[0].find(
      (item) => item.companyId === this.user?.companyId
    )?.isAgentIdentified;
  }
  selectEmoji(emoji) {
    this.message = `${this.message} ${emoji.data}`;
    this.openEmojiPicker = false;
  }

  closeHistoricDialog() {
    this.$emit("close-historic-dialog", false);
  }
  clearCurrenctAttendency(value) {
    this.$emit("clear-currenct-attendency", value);
  }
  updateMessage(value) {
    this.$emit("update-message", value);
  }
  async sendLocation() {
    const location = {
      latitude: this.position.latitude,
      longitude: this.position.longitude,
    };
    const entity = {
      userName: this.user.firstName,
      to: this.conversation.contact.phone,
      type: "location",
      message: location,
      channelId: this.conversation.attendency.channelId,
    };

    const result = await this.post(paths.sendMessagePath, entity);

    const messageToEmit = {
      userName: this.user.firstName,
      fromMe: this.fromMe,
      message: entity.message,
      typeMessage: "location",
    };

    this.$emit("update-message", messageToEmit);

    return result;
  }
  async getMedia(constraints) {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    return stream;
  }
  uploadSrc(src) {
    this.src = src;
    this.overlay = true;
  }
  updateDrawerContact() {
    const drawerContact = !this.drawerContact;
    this.$emit("drawerContact", drawerContact);
  }
  updateDrawer() {
    this.$emit("update-drawer", !this.drawer);
  }
  openDrawerSearch() {
    this.$emit("update-drawer-search", !this.drawerSearch);
  }
  openDrawerHistory() {
    this.$emit("update-drawer-history", !this.drawerHistory);
  }
  setStyle() {
    document.getElementById("left").style.display = "block";
    document.getElementById("main").style.display = "none";
  }
  endMessage(value) {
    this.addMessage(value, "end_message");
  }
  async addMessage(element, typeMessage?) {
    const { channelType, channelId, protocolNumber } =
      this.conversation.attendency;

    const { phone, email, id, firstName } = this.conversation.contact;
    if (channelType === "C") {
      if (this.hasNamePermission) {
        const fullName = `${this.user.firstName} ${this.user.lastName} `;

        element = `${fullName} ${translate("say")} ${element}`;
      }
      const room = firstName ? firstName + email : "";
      const message = {
        text: element,
        username: this.user.firstName,
        date: new Date(),
        fromMe: this.fromMe,
        room: room,
        channelId: channelId,
        protocolNumber: protocolNumber,
        senderType: this.fromMe ? "agente" : "cliente",
      };
      typeMessage === "end_message"
        ? this.socket.emit("end_message", message)
        : this.socket.emit("message", message);
    }

    if (channelType === "W") {
      if (this.hasNamePermission) {
        const fullName = `${this.user.firstName} ${this.user.lastName} `;

        element = `*${fullName} ${translate("say")}*:\n  ${element}`;
      }

      const message = {
        userName: this.user.firstName,
        protocolNumber: protocolNumber,
        to: phone,
        fromMe: this.fromMe,
        receptive: false,
        type: "text",
        message: element,
        channelId: channelId,
        contactId: id,
      };

      const result = await this.post(
        this.paths.sendMessagePath,
        message,
        false
      );

      const messageToEmit = {
        ...result,
        userName: this.user.firstName,
        fromMe: this.fromMe,
      };

      this.$emit("update-message", messageToEmit);
    }
  }
  sendMessage(message) {
    this.addMessage(message);
    this.message = "";
  }
  sendOnClick() {
    this.sendMessage(this.message);
    this.message = "";
  }
  sendOnPressEnter(e) {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        this.message += "\n";
      } else if (e.target.value.trim()) {
        this.addMessage(e.target.value);

        this.message = "";
      }
    }
  }
  getData = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file[0]);

    reader.onload = async () => {
      const { type } = file[0];
      let mediaType;

      if (type.startsWith("video")) {
        mediaType = "video";
      } else if (type.startsWith("image")) {
        mediaType = "image";
      } else if (type.startsWith("audio")) {
        mediaType = "audio";
      } else if (type.startsWith("application")) {
        mediaType = "document";
      } else {
        this.$message.errorMessage(`${translate("invalidFormat")}`);
        return;
      }

      const media = {
        data: reader.result,
        contentType: mediaType,
        name: file[0].name,
        channelId: this.conversation.attendency.channelId,
      };
      const result = await this.post(paths.sendToConsumerPath, media);

      if (result.mediaId) {
        type TEntity = {
          userName: string;
          to: string;
          type: string;
          message: string;
          mediaBase64: string | ArrayBuffer;
          channelId: number;
          contactId: number;
        };
        const entity: TEntity = {
          userName: this.user.firstName,
          to: this.conversation.contact.phone,
          type: mediaType,
          message: result.mediaId,
          mediaBase64: reader.result,
          channelId: this.conversation.attendency.channelId,
          contactId: this.conversation.attendency.contactId,
        };

        const resultMedia = await this.post(paths.sendMessagePath, entity);

        const messageToEmit = {
          ...resultMedia,
          userName: this.user.firstName,
          fromMe: this.fromMe,
          message: reader.result,
        };

        this.$emit("update-message", messageToEmit);

        return resultMedia;
      }
    };
  };

  startRecord() {
    this.audioChunks = [];
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      this.mediaRecorder = new MediaRecorder(stream, {
        mimeType: "audio/webm",
      });
      this.mediaRecorder.start(1000);

      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };
    });
    this.isRecording = true;
  }
  stopRecord() {
    this.mediaRecorder.stop();
    this.isRecording = false;
    this.mediaRecorder.addEventListener("stop", () => {
      const audioBlob = new Blob(this.audioChunks);
      var file = new File([audioBlob], "name", { type: "audio/webm" });
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        this.recordedVoice = URL.createObjectURL(audioBlob);

        const path = paths;
        this.mediaType = "audio";

        const media = {
          data: reader.result,
          contentType: this.mediaType,
          name: "teste",
          channelId: this.conversation.attendency.channelId,
        };

        const result = await this.post(path.sendToConsumerPath, media);
        type TEntity = {
          userName: string;
          to: string;
          type: string;
          message: number;
          mediaBase64: string | ArrayBuffer;
          channelId: number;
          contactId: number;
        };
        if (result.mediaId) {
          let entity: TEntity = {
            userName: this.user.firstName,
            to: this.conversation.contact.phone,
            type: this.mediaType,
            message: result.mediaId,
            mediaBase64: reader.result,
            channelId: this.conversation.attendency.channelId,
            contactId: this.conversation.attendency.contactId,
          };

          const resultMedia = await this.post(path.sendMessagePath, entity);
          const messageToEmit = {
            ...resultMedia,
            userName: this.user.firstName,
            fromMe: this.fromMe,
            message: reader.result,
          };

          this.$emit("update-message", messageToEmit);
          return resultMedia;
        }
      };
    });
  }
  get newConversation() {
    return this.conversation;
  }

  get missingMessage() {
    return !this.message;
  }

  get saveDisabled() {
    return this.missingMessage;
  }
}
